<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('addcommercial_jbxx', false)">基本信息</span>
      <span @click="toUrl('addcommercial_jsxx', false)">结算信息</span>
      <span @click="toUrl('addcommercial_qdxx', false)">渠道信息</span>
      <span id="fzcolor">产品信息</span>
    </div>
    <div class="desc">
        <h5 class="jsxx">支付产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>渠道商户号</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>结算费率(%)</p>
          </td>
          <td>
            <p>大额费率(%)</p>
          </td>
          <td>
            <p>结算周期</p>
          </td>
          <td>
            <p>状态</p>
          </td>
          <td>
            <p>IP制导</p>
          </td>
          <!-- <td>
						<p>操作</p>
					</td> -->
        </tr>
        <template v-for="(val, ind) in payProducts">
          <tr v-for="(v, i) in val" :key="ind + '-' + i">
            <td style="padding-left: 10px">
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.payChannelName }}</p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.largeRate }}</p>
            </td>
            <td>
              <p>{{ v.settleCycle }}</p>
            </td>
            <td>
              <p>
                <el-switch v-model="v.enabledStatus" active-color="#13ce66">
                </el-switch>
              </p>
            </td>
            <td>
              <p>
                <el-switch v-model="v.ipStatus" active-color="#13ce66">
                </el-switch>
              </p>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <!-- <h6 class="xzjsxx" @click="addPayTab"><span>+</span> 新增支付产品</h6> -->
    <div class="desc">
        <h5 class="jsxx">代付产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>渠道商户号</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>费率(%)</p>
          </td>
          <td>
            <p>对公代付(元/笔)</p>
          </td>
          <td>
            <p>对私代付(元/笔)</p>
          </td>
          <td>
            <p>状态</p>
          </td>
          <!-- <td>
						<p>操作</p>
					</td> -->
        </tr>
        <template v-for="(val, ind) in agnpayProducts">
          <tr v-for="(v, i) in val" :key="ind + '-' + i">
            <td style="padding-left: 10px">
              <p>
                {{
                  v.payType == "BANK_PAY_DAILY_ORDER"
                    ? "朝夕付"
                    : v.payType == "BANK_PAY_MAT_ENDOWMENT_ORDER"
                    ? "任意付"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.agnpayChannelName }}</p>
            </td>
            <td>
              <p>{{ v.payRate }}</p>
            </td>
            <td>
              <p>{{ v.payCostPublic | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.payCostPrivate | formatMoney }}</p>
            </td>
            <td>
              <p>
                <el-switch v-model="v.enabledStatus" active-color="#13ce66">
                </el-switch>
              </p>
            </td>
            <!-- <td>
							<p><i class="lianjie" @click="delAgnPayTab(ind,i)">删除</i></p>
						</td> -->
          </tr>
        </template>
      </table>
    </div>
    <!-- <h6 class="xzjsxx" @click="addAgnPayTab"><span>+</span> 新增代付产品</h6> -->
    <div class="desc">
        <h5 class="jsxx">分账产品</h5>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="padding-left: 10px">
            <p>产品类型</p>
          </td>
          <td>
            <p>渠道商户号</p>
          </td>
          <td>
            <p>产品通道</p>
          </td>
          <td>
            <p>结算费率(%)</p>
          </td>
          <td>
            <p>结算成本(元/笔)</p>
          </td>
          <td>
            <p>状态</p>
          </td>
          <!-- <td>
						<p>操作</p>
					</td> -->
        </tr>
        <template v-for="(val, ind) in allocProducts">
          <tr v-for="(v, i) in val" :key="ind + '-' + i">
            <td style="padding-left: 10px">
              <p>
                {{
                  v.settleType == "AUTO"
                    ? "自动结算"
                    : v.settleType == "MANUAL"
                    ? "手工结算"
                    : ""
                }}
              </p>
            </td>
            <td>
              <p>{{ v.mchNo }}</p>
            </td>
            <td>
              <p>{{ v.allocChannelName }}</p>
            </td>
            <td>
              <p>{{ v.settleRate }}</p>
            </td>
            <td>
              <p>{{ v.settleCost | formatMoney }}</p>
            </td>
            <td>
              <p>
                <el-switch v-model="v.enabledStatus" active-color="#13ce66">
                </el-switch>
              </p>
            </td>
            <!-- <td>
							<p><i class="lianjie" @click="delAllTab(ind,i)">删除</i></p>
						</td> -->
          </tr>
        </template>
      </table>
    </div>
    <!-- <h6 class="xzjsxx" @click="addAllTab"><span>+</span> 新增分账产品</h6> -->
    <div class="btn">
      <el-button @click="toUrl('addcommercial_qdxx', false)" class="back"
        >上一步</el-button
      >
      <el-button @click="submit" class="next" type="primary">保存</el-button>
    </div>
    <!-- <el-dialog title="新增支付产品" :visible.sync="userPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
          <el-select
            v-model="mchChannelName"
            placeholder="选择关联渠道"
            @change="mchChannelNameChange"
          >
            <el-option
              v-for="(v, i) in formData"
              :key="i"
              :label="v.mchNo"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>支付类型</div>
          <el-select
            v-model="payFormData.productId"
            placeholder="选择支付类型"
            @change="payDropChange"
            :disabled="!mchChannelName && mchChannelName !== 0"
          >
            <el-option
              v-for="(v, i) in payDropLists"
              :key="i"
              :label="v.productName"
              :value="v.productId"
              :disabled="v.disabled"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="userPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddPayTab">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- <el-dialog title="新增代付产品" :visible.sync="hasPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
          <el-select
            v-model="mchChannelName"
            placeholder="选择关联渠道"
            @change="mchChannelNameChange1"
          >
            <el-option
              v-for="(v, i) in formData"
              :key="i"
              :label="v.mchNo"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>代付类型</div>
          <el-select
            v-model="agnPayFormData.payType"
            placeholder="选择代付类型"
            @change="agnPayDropChange"
            :disabled="!mchChannelName && mchChannelName !== 0"
          >
            <el-option
              v-for="(v, i) in AgnpayDropOption"
              :key="i"
              :label="v.title"
              :value="v.value"
              :disabled="v.disabled"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="hasPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddAgnPayTab">确 定</el-button>
      </span>
    </el-dialog> -->
    <!-- <el-dialog title="新增分账产品" :visible.sync="accountShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>关联渠道</div>
          <el-select
            v-model="mchChannelName"
            placeholder="选择关联渠道"
            @change="mchChannelNameChange2"
          >
            <el-option
              v-for="(v, i) in formData"
              :key="i"
              :label="v.mchNo"
              :value="i"
            >
            </el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>分账结算类型</div>
          <el-select
            v-model="allFormData.settleType"
            placeholder="选择分账结算类型"
            @change="allDropChange"
            :disabled="!mchChannelName && mchChannelName !== 0"
          >
            <el-option
              v-for="(v, i) in allDropOption"
              :key="i"
              :label="v.title"
              :value="v.value"
              :disabled="v.disabled"
            >
            </el-option>
          </el-select>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="accountShow = false">取 消</el-button>
        <el-button class="addBt" @click="sureAddAllTab">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import {
  payDrop,
  agnpayDrop,
  allDrop,
  addShop,
} from "@/api/user/shopManager.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      // userPayShow: false, //新增支付展示
      // hasPayShow: false, //新增代付展示
      // accountShow: false, //新增分账展示
      payProducts: [], //支付产品列表
      agnpayProducts: [], //代付产品列表
      allocProducts: [], //分账产品列表
      formData: [], //渠道信息
      // payFormData: {
      //   //支付产品
      //   productId: "", //true	支付产品ID
      //   payChannelId: "", //true	支付产品通道ID
      //   refundStrategy: "", //true	退款策略
      //   settleCycle: "", //true	结算周期，
      //   settleRate: "", //true	结算费率%
      //   largeRate: "", //false	大额费率%
      //   dayLimit: "", //true	单日限额，单位分
      //   singleMinimumLimit: "", //true	单笔最小限额，单位分
      //   singleMaximumLimit: "", //true	单笔最大限额，单位分
      //   riskThreshold: "", //true	风控阈值（0-100）
      //   riskStatus: "", //true	风控状态
      // },
      // payDropLists: [], //支付产品列表
      // payDropListsChild: [], //付产品通道列表
      // editPayIndex: null,
      // editPayIndexChild: null,
      // agnPayFormData: {
      //   //代付产品
      //   payType: "", //true	代付产品类型
      //   agnpayChannelId: "", //true	代付产品通道ID
      //   payRate: "", //true	代付费率%
      //   payCostPublic: "", //true	对公代付（单位分/笔）
      //   payCostPrivate: "", //true	对私代付（单位分/笔）
      //   dayLimit: "", //true	单日限额，单位分
      //   singleLimit: "", //true	单笔限额，单位分
      // },
      // AgnpayDropLists: [], //代付产品类型列表
      // AgnpayDropOption: [
      //   {
      //     title: "朝夕付",
      //     value: "BANK_PAY_DAILY_ORDER",
      //     disabled: false,
      //   },
      //   {
      //     title: "任意付",
      //     value: "BANK_PAY_MAT_ENDOWMENT_ORDER",
      //     disabled: false,
      //   },
      // ],
      // AgnpayDropListsChild: [], //代付产品通道列表
      // editAgnPayIndex: null,
      // editAgnPayIndexChild: null,
      // allFormData: {
      //   //分账展示
      //   settleType: "", //true	结算方式
      //   allocChannelId: "", //true	分账产品通道ID
      //   settleRate: "", //true	结算费率%
      //   settleCost: "", //true	结算成本（单位分/笔）
      // },
      // allDropLists: [], //结算方式列表
      // allDropOption: [
      //   {
      //     title: "自动结算",
      //     value: "AUTO",
      //     disabled: false,
      //   },
      //   {
      //     title: "手工结算",
      //     value: "MANUAL",
      //     disabled: false,
      //   },
      // ],
      // allDropListsChild: [], //分账产品通道列表
      // editAllIndex: null,
      // editAllIndexChild: null,
      mchChannelName: "", //新增渠道
    };
  },
  computed: {
    ...mapState({
      businessDetail: "user_businessDetail",
    }),
  },
  mounted() {
    if (this.businessDetail) {
      let data = this.businessDetail.mchChannels
        ? this.businessDetail.mchChannels
        : [];
      data.forEach((v, i) => {
        if (v.mchChannelDetail.mchChannelId) {
          v.payProducts.forEach((val) => {
            val.mchChannelId = v.mchChannelDetail.mchChannelId;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
          v.agnpayProducts.forEach((val) => {
            val.mchChannelId = v.mchChannelDetail.mchChannelId;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
          v.allocProducts.forEach((val) => {
            val.mchChannelId = v.mchChannelDetail.mchChannelId;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
        } else {
          v.mchChannelDetail.mchChannelId = i;
          v.payProducts.forEach((val) => {
            val.mchChannelId = i;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
          v.agnpayProducts.forEach((val) => {
            val.mchChannelId = i;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
          v.allocProducts.forEach((val) => {
            val.mchChannelId = i;
            val.mchChannelName = v.mchChannelDetail.mchChannelName;
          });
        }
        this.formData.push(v.mchChannelDetail);
        this.payProducts.push(v.payProducts);
        this.agnpayProducts.push(v.agnpayProducts);
        this.allocProducts.push(v.allocProducts);

        this.payProducts = JSON.parse(JSON.stringify(this.payProducts));
        this.agnpayProducts = JSON.parse(JSON.stringify(this.agnpayProducts));
        this.allocProducts = JSON.parse(JSON.stringify(this.allocProducts));

      });
    }
  },
  methods: {
    ...mapMutations({
      setBusiness: "user_setBusiness",
    }),
    // 获取支付下拉选择
    // async getPayDrop(value) {
    //   let data = {
    //     orgId: value,
    //     mchChannelId: "",
    //   };
    //   await payDrop(data).then((res) => {
    //     this.payDropLists = res.resultData;
    //     this.payDropLists.forEach((v) => {
    //       v.disabled = false;
    //     });
    //     this.payProducts[this.mchChannelName].forEach((v) => {
    //       this.payDropLists.forEach((val) => {
    //         if (v.productId == val.productId) {
    //           val.disabled = true;
    //         }
    //       });
    //     });
    //   });
    // },
    // 支付渠道选择
    // mchChannelNameChange(index) {
    //   this.getPayDrop(this.formData[index].orgId);
    // },
    // 支付下拉选择
    // payDropChange(value) {
    //   this.payFormData.payChannelId = "";
    //   this.payDropListsChild = [];
    //   this.payDropLists.forEach((v) => {
    //     if (v.productId === value) {
    //       this.payDropListsChild = v.payChannels;
    //     }
    //   });
    // },
    // 新增支付
    // addPayTab() {
    //   (this.payFormData = {
    //     productId: "",
    //     payChannelId: "",
    //     refundStrategy: "WITH_SERVICE_CHARGE",
    //     settleCycle: "T+1",
    //     settleRate: 0,
    //     largeRate: 0,
    //     dayLimit: 0,
    //     singleMinimumLimit: 0,
    //     singleMaximumLimit: 0,
    //     riskThreshold: 0,
    //     riskStatus: 0,
    //   }),
    //     (this.userPayShow = true);
    //   this.editPayIndex = null;
    //   this.mchChannelName = "";
    // },
    // 新增支付确认
    // sureAddPayTab() {
    //   let data = JSON.parse(JSON.stringify(this.payFormData));
    //   if (!data.productId) {
    //     this.$message.error("支付类型必选");
    //     return false;
    //   }
    //   data.enabledStatus = false;
    //   data.payChannelName = "";
    //   this.payDropLists.forEach((v) => {
    //     if (v.productId == data.productId) {
    //       data.productName = v.productName;
    //     }
    //     data.payChannelId = v.payChannels[0].payChannelId;
    //     data.payChannelName = v.payChannels[0].payChannelName;
    //   });
    //   data.mchChannelId = this.mchChannelName;
    //   this.formData.forEach((v) => {
    //     if (v.mchChannelId == data.mchChannelId) {
    //       data.mchChannelName = v.mchChannelName;
    //       data.mchNo = v.mchNo;
    //     }
    //   });
    //   this.payProducts[this.mchChannelName].push(
    //     JSON.parse(JSON.stringify(data))
    //   );
    //   this.$message.success("保存成功");
    //   this.userPayShow = false;
    // },
    // 删除支付
    // delPayTab(index, indexChild) {
    //   this.$confirm("此操作将删除该支付产品, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.payProducts[index].splice(indexChild, 1);
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 获取代付下拉选择
    // async getAgnpayDrop(id) {
    //   let data = {
    //     orgId: id,
    //     mchChannelId: "",
    //   };
    //   await agnpayDrop(data).then((res) => {
    //     this.AgnpayDropLists = res.resultData;
    //     this.AgnpayDropOption.forEach((val) => {
    //       val.disabled = false;
    //     });
    //     this.agnpayProducts[this.mchChannelName].forEach((v) => {
    //       this.AgnpayDropOption.forEach((val) => {
    //         if (v.payType == val.value) {
    //           val.disabled = true;
    //         }
    //       });
    //     });
    //   });
    // },
    // mchChannelNameChange1(index) {
    //   this.getAgnpayDrop(this.formData[index].orgId);
    // },
    // 代付下拉选择
    // agnPayDropChange(value) {
    //   this.agnPayFormData.agnpayChannelId = "";
    //   this.AgnpayDropListsChild = [];
    //   this.AgnpayDropLists.forEach((v) => {
    //     if (v.payType === value) {
    //       this.AgnpayDropListsChild = v.agnpayChannels;
    //     }
    //   });
    // },
    // 新增代付
    // addAgnPayTab() {
    //   (this.agnPayFormData = {
    //     payType: "",
    //     agnpayChannelId: "",
    //     payRate: 0,
    //     payCostPublic: 0,
    //     payCostPrivate: 0,
    //     dayLimit: 0,
    //     singleLimit: 0,
    //   }),
    //     (this.hasPayShow = true);
    //   this.editAgnPayIndex = null;
    //   this.mchChannelName = "";
    // },
    // 新增代付确认
    // sureAddAgnPayTab() {
    //   let data = JSON.parse(JSON.stringify(this.agnPayFormData));
    //   if (!data.payType) {
    //     this.$message.error("代付类型必选");
    //     return false;
    //   }
    //   data.enabledStatus = false;
    //   data.agnpayChannelId = this.AgnpayDropListsChild[0]
    //     ? this.AgnpayDropListsChild[0].agnpayChannelId
    //     : "";
    //   data.agnpayChannelName = this.AgnpayDropListsChild[0]
    //     ? this.AgnpayDropListsChild[0].agnpayChannelName
    //     : "";
    //   data.mchChannelId = this.mchChannelName;
    //   this.formData.forEach((v) => {
    //     if (v.mchChannelId == data.mchChannelId) {
    //       data.mchChannelName = v.mchChannelName;
    //       data.mchNo = v.mchNo;
    //     }
    //   });
    //   this.agnpayProducts[this.mchChannelName].push(
    //     JSON.parse(JSON.stringify(data))
    //   );
    //   this.$message.success("保存成功");
    //   this.hasPayShow = false;
    // },
    // 删除代付
    // delAgnPayTab(index, indexChild) {
    //   this.$confirm("此操作将删除该代付产品, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.agnpayProducts[index].splice(indexChild, 1);
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 获取分账下拉选择
    // async getAllDrop(id) {
    //   let data = {
    //     orgId: id,
    //     mchChannelId: "",
    //   };
    //   await allDrop(data).then((res) => {
    //     this.allDropLists = res.resultData;
    //     this.allDropOption.forEach((val) => {
    //       val.disabled = false;
    //     });
    //     this.allocProducts[this.mchChannelName].forEach((v) => {
    //       this.allDropOption.forEach((val) => {
    //         if (v.settleType == val.value) {
    //           val.disabled = true;
    //         }
    //       });
    //     });
    //   });
    // },
    // mchChannelNameChange2(index) {
    //   this.getAllDrop(this.formData[index].orgId);
    // },
    // 分账下拉选择
    // allDropChange(value) {
    //   this.allFormData.allocChannelId = "";
    //   this.allDropListsChild = [];
    //   this.allDropLists.forEach((v) => {
    //     if (v.settleType === value) {
    //       this.allDropListsChild = v.allocChannels;
    //     }
    //   });
    // },
    // 新增分账
    // addAllTab() {
    //   (this.allFormData = {
    //     settleType: "",
    //     allocChannelId: "",
    //     settleRate: 0,
    //     settleCost: 0.01,
    //   }),
    //     (this.accountShow = true);
    //   this.editAllIndex = null;
    //   this.mchChannelName = "";
    // },
    // 新增分账确认
    // sureAddAllTab() {
    //   let data = JSON.parse(JSON.stringify(this.allFormData));
    //   if (!data.settleType) {
    //     this.$message.error("产品类型必选");
    //     return false;
    //   }
    //   data.enabledStatus = false;
    //   data.allocChannelId = this.allDropListsChild[0]
    //     ? this.allDropListsChild[0].allocChannelId
    //     : "";
    //   data.allocChannelName = this.allDropListsChild[0]
    //     ? this.allDropListsChild[0].allocChannelName
    //     : "";
    //   data.mchChannelId = this.mchChannelName;
    //   this.formData.forEach((v) => {
    //     if (v.mchChannelId == data.mchChannelId) {
    //       data.mchChannelName = v.mchChannelName;
    //       data.mchNo = v.mchNo;
    //     }
    //   });
    //   this.allocProducts[this.mchChannelName].push(
    //     JSON.parse(JSON.stringify(data))
    //   );
    //   this.$message.success("保存成功");
    //   this.accountShow = false;
    // },
    // 删除分账
    // delAllTab(index, indexChild) {
    //   this.$confirm("此操作将删除该分账产品, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.allocProducts[index].splice(indexChild, 1);
    //       this.$message({
    //         type: "success",
    //         message: "删除成功!",
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消删除",
    //       });
    //     });
    // },
    // 页面跳转
    toUrl(name) {
      this.$router.push({
        name: name,
      });
    },
    submit() {
      let formData = [];
      this.formData.forEach((v, i) => {
        let data = {
          mchChannelDetail: v,
          payProducts: this.payProducts[i],
          agnpayProducts: this.agnpayProducts[i],
          allocProducts: this.allocProducts[i],
        };
        formData.push(data);
      });
      let tabData = {
        mchDetail: this.businessDetail ? this.businessDetail.mchDetail : null,
        settlements: this.businessDetail
          ? this.businessDetail.settlements
          : null,
        mchChannels: formData,
      };
      this.setBusiness(tabData);
      let data = {
        mchDetail: this.businessDetail
          ? JSON.parse(JSON.stringify(this.businessDetail.mchDetail))
          : null,
        settlements: this.businessDetail
          ? JSON.parse(JSON.stringify(this.businessDetail.settlements))
          : null,
        mchChannels: this.businessDetail
          ? JSON.parse(JSON.stringify(this.businessDetail.mchChannels))
          : null,
      };
      data.mchChannels.forEach((v) => {
        v.payProducts.forEach((val) => {
          val.dayLimit *= 100;
          val.singleMinimumLimit *= 100;
          val.singleMaximumLimit *= 100;
        });
        v.agnpayProducts.forEach((val) => {
          val.payCostPublic *= 100;
          val.payCostPrivate *= 100;
          val.dayLimit *= 100;
          val.singleLimit *= 100;
        });
        v.allocProducts.forEach((val) => {
          val.settleCost *= 100;
        });
      });
      console.log("提交的数据",data)
      // return
      addShop(data).then((res) => {
        if (res.resultStatus) {
          this.setBusiness(null);
          this.$router.push({
            name: "businessList_shlb",
          });
        }
      });
    },
  },
  beforeDestroy() {
    let formData = [];
    this.formData.forEach((v, i) => {
      let data = {
        mchChannelDetail: v,
        payProducts: this.payProducts[i],
        agnpayProducts: this.agnpayProducts[i],
        allocProducts: this.allocProducts[i],
      };
      formData.push(data);
    });
    let data = {
      mchDetail: this.businessDetail ? this.businessDetail.mchDetail : null,
      settlements: this.businessDetail ? this.businessDetail.settlements : null,
      mchChannels: formData,
    };
    if (this.businessDetail) {
      this.setBusiness(data);
    }
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
  margin-top: 50px;
  margin-bottom: 18px;
}

.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.overAccount_ul .ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #5aafab;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  border-color: #dce0e6;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
</style>
